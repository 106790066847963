#root {
  height: auto !important;
  min-height: 100%;
  background-color: #f8f9fa !important;
}
.ant-message{
  z-index: 999999;
}
.ant-message-notice .anticon {
  vertical-align: text-top;
}
.grecaptcha-badge { visibility: hidden; }

.ageSpan{
  color: #3d1ff5;
  cursor: pointer;
}