.end-item .ant-form-item-control-input{
    margin: auto;
}
.ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-start !important;
}

.end-item .ant-form-item-control-input-content{
    justify-content: center !important;
}