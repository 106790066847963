.ant-input-search-button{
    display: flex !important;
    align-items: center !important;
}
.modal-content{
    width: 70% !important;
    margin: 0 auto !important;
}
.ant-tabs-nav-list{
    width: 100% !important;
}
.ant-tabs-tab{
    width: 50% !important;
    justify-content: center !important;
}
.code-con{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.walletconnect-qrcode__text {
    color: rgba(60, 66, 82, 0.6);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.1875em;
    margin: 10px 0 20px 0;
    text-align: center;
    width: 100%;
}
.anticon {
    vertical-align: text-top !important;
}

.btn-primary-ori {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}