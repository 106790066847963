.nft-block-xl {
    height: auto;
    min-height: 400px;
    max-width: 650px;
}

.nft-block-img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.35rem;
}

.border-md-start {
    border-left: 1px solid #e9ecef;
}

.ant-collapse-item {
    background: #fff;
}

.nft-empty-img {
    width: 128px;
    min-height: 450px;
    display: flex;
}