.u-header {
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}
.u-header__section {
  position: relative;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 1px 10px rgb(151 164 175 / 10%);
}
.testFlag{
  background-color: #de4437;
  color: #fff;
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
  font-size: 80%;
  margin-left: 0.5em;
}
.head-portrait{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}