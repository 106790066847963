.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: 0.75rem 2rem 0.75rem 1rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1e2022;
    vertical-align: middle;
    /* background: #fff url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3E%3Cpath fill='%233e444a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E) no-repeat right 1rem center/0.5rem 0.63rem; */
    border: 1px solid #d5dae2;
    border-radius: 0.35rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.ant-input-prefix{
    height: 18px;
}

.ant-form-item-control-input-content{
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 576px){
    .modal-dialog {
        max-width: 800px;
    }
}
