
.salesExtra {
    display: inline-block;
    margin-right: 24px;
    
  }
  .salesExtra a {
    margin-left: 24px;
    color: #666;
    cursor: pointer;
  }
  .salesExtra a:hover {
    color: #1890ff;
  }
  .salesExtra a.currentDate, a.useCurrentDate, a.orderCurrentDate {
    color: #1890ff;
  }

  @media (max-width: 768px){
    .ant-picker-panels{
      display: flex;
      flex-direction: column;
    }
    .head {
      /* width: 80%; */
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ant-picker{
      display: none;
    }
    .salesExtra{
      margin-bottom: 10px;
    }
  }
