.ant-form-item-control-input-content{
    display: flex;
    justify-content: space-between;
}
.end-item .ant-form-item-control-input-content{
    flex-direction: column;
    justify-content: start;
}
.end-item{
    width: 60%;
    margin: 50px auto;
}