.searchBox{
    background: #fff;
    position: absolute;
    top: 35px;
    right: 0;
    width: 300px;
    z-index: 99;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 7px rgb(52 152 219 / 5%), 0 0 10px hsl(210deg 8% 46% / 10%);
}
.ant-btn-primary{
    background: #3d1ff5;
}
.btn-icon__inner{
    top: 55%;
}
.ant-typography{
    margin-top: -3px;
}